
/*** MAIN SECTION ********************************/
.main {
    margin: 0;
    padding: 0 5%;
    @include laptop {
        margin: 0 auto;
        padding: 0;
        max-width: 90%;
    }
    @include desktop { max-width: 1250px; }
}



/*** BG CHAMPETRE ********************************/
.bg_champetre {
    padding-bottom: 225px;
    background: url(../img/bg/champetre_color.jpg) no-repeat;
    background-position: bottom -150px center;
}
.bg_champetre--fat {
    background: url(../img/bg/champetre-fat.jpg) no-repeat center bottom;
}



/*** STATUS MESSAGE ********************************/
.messages {
  max-width: 1250px;
  margin: 0 auto 20px;
  border : 1px solid transparent;
  padding: 10px 30px;
}

.messages--error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.messages--status {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}




/*** ITEM ACTU ***********************************/
.item_actu {
    display: block;
    margin-top: 15px;
    &:first-child { margin-top: 0; }
    @include laptop { margin-top: 0; }
    padding: 15px;

    background: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    transition: $transition-base;

    // RESET
    color: inherit;
    text-decoration: none;

    // HOVER
    @include laptop {
        &:hover { box-shadow: 0 3px 20px rgba(0, 0, 0, 0.45); }
    }

    // ILLU
    .item_actu_illu {

        img {
            @include supaclean;
            width: 100%;
            height: auto;
        }
    }

    // CONTENT
    .item_actu_content {
        padding: 0 4px 10px;
    }

    .item_actu_header {
        display: flex;
        margin: 7px 0 0 0;
    }

    .item_actu_categ {
        color: $couleur_1;
        font-weight: 500;
    }

    // TITLE
    .item_actu_titre {
        margin-top: 10px;

        color: $black;
        @include fontsize(14);
        @include lineheight(18);
        font-weight: 500;
        font-style: italic;
    }

    // DATE
    .item_actu_date {
        margin: 5px 0 0 auto;

        color: $green;
        @include fontsize(12);
        @include lineheight(12);
        font-weight: 500;
        font-style: italic;
    }

    // DESC
    .item_actu_desc { margin-top: 10px; }

    // LINK
    .item_actu_link {
        margin-top: 10px;

        color: $blue;
        @include fontsize(13);
        @include lineheight(13);
        font-weight: 500;
        text-decoration: underline;

        // RESET
        a { color: inherit; }
    }
}



/*** COMMON GREEN HEADER ******************************/
.commonHeader {
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &_bgcolor {
        padding: 30px 0 50px;
        @include laptop { padding: 50px 0 60px; }
        background: rgba(180,195,13,0.84);
    }

    // TITRE
    .commonHeader_titre {
        margin-bottom: 30px;
        @extend .h2_like;
        @extend .souligne--fat;
        background: url(../img/deco/underline-fat-white.svg) no-repeat center bottom / 100px 5px !important;
        @include fontsize(22);
        @include lineheight(26);
        color: $white;
        text-align: center;

        @include tablet {
            @include fontsize(26);
            @include lineheight(32);
        }
    }

    // DESC
    .commonHeader_desc {
        p {
            max-width: 950px;
            margin: 15px auto 0;
            &:first-child { margin-top: 0; }
            color: $white;
            text-align: center;
        }
    }

    ul {
        margin: 15px auto;
        max-width: 900px;

        li {
            position: relative;
            padding-left: 15px;
            color: $white;

            // PUPUCE
            &:before {
                content: "";

                position: absolute;
                top: 8px;
                left: 0;

                width: 6px;
                height: 6px;

                background: $couleur_1;
            }
        }
    }
}



/*** BLOCK CHIFFRES CLES *************************/
.paragraph--type--key-figures {
    padding: 20px 5%;
    @include laptop { padding: 40px 0; }

    // TITRE
    .pg_titre {
        @extend .h2_like;
        @extend .center;
        @extend .souligne--fat;
        margin-bottom: 30px;
    }
}

// CONTENT
.block_ChiffresCles {

    .main {
        display: flex;
        flex-direction: row;
        justify-content: center;
        @include phonetabletonly { flex-wrap: wrap; }
    }

    .block_ChiffresCles_item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        // flex-wrap: wrap;

        @include phonetabletonly {
            width: 50%;
            margin-top: 30px;
            &:nth-child(1),
            &:nth-child(2) { margin-top: 0; }
        }

        padding: 0 25px;

        // TIRET
        @include laptop {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 30px;
                left: 0;

                width: 1px;
                height: 50px;
                background: $grey_light;
            }
            &:first-child {
                &:before { content: none; }
            }
        }
    }

    // CHIFFRE
    .block_ChiffresCles_value {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        margin-bottom: 10px;

        width: 100px;
        height: 100px;

        color: $white;
        @include fontsize(28);
        @include lineheight(28);
        font-weight: 500;

        background: url(../img/bg/chiffres-cles.png) no-repeat center bottom;
    }

    // LABEL
    .block_ChiffresCles_label {
        color: $black;
        @include fontsize(12);
        @include lineheight(16);
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    }
}



/*** BLOCK FULL IMG TEXT *************************/
.pg_full_image_texte {
    margin: 20px 0;

    @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px 0;
    }

    // ILLUSTRATION
    .gauche {

        width: 100%;
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include laptop {
            width: 45%;
            height: auto;
        }
    }

    // DESC
    .droite {
        background: $couleur_1;

        @include laptop {
            width: 55%;

            background: url(../img/bg/bles_vert.jpg) no-repeat center;
            background-size: cover;
        }

        .droite_deco {
            padding: 20px 5%;
            background: none;

            @include laptop {
                display: flex;
                flex-direction: column;

                padding: 80px 0 80px 60px;

                background: url(../img/bg/hexa-triple-green.png) no-repeat left 620px center;
            }
        }

        // SPECIAL CONTENT
        .specialContent {
            @include laptop { width: 500px; }

            // RESET PG
            .pg_titre_txt {
                margin-bottom: 30px;
                padding: 0;
            }

            // TITRE
            .pg_titre {
                margin-bottom: 20px;
                padding-bottom: 15px;

                @extend .h3_like;
                @extend .souligne--fat;
                color: $white;
                text-transform: uppercase;

                // RESET
                text-align: left !important;
                background-position: left bottom !important;
            }

            // TEXTE
            .pg_texte {
                color: $white;
                text-align: left;
                p {
                    margin-top: 15px;
                    &:first-child { margin-top: 0; }
                }

                ul li:before {
                    background: $couleur_2;
                }
            }
        }

        // LIENS
        .liens {
            margin-top: 30px;
            > div {
                margin-top: 10px;
                &:first-child { margin-top: 0; }
            }
            a {
                @extend .btn_base;
                //@extend .btn_base--white;
            }
        }
    }

    // INVERSE
    @include laptop {
        &.pg_full_image_texte--inverse {
            flex-direction: row-reverse;
            .droite_deco {
                align-items: flex-end;
                padding: 80px 60px 80px 0;
                background: url(../img/bg/hexa-triple-green.png) no-repeat right 620px center;
            }
        }
    }
}



/*** BLOCK IMAGE TEXTE ***************************/
.pg_image_texte {
    padding: 20px 5%;

    @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 40px 0;
    }

    // ILLUSTRATION
    .gauche {
        margin-bottom: 20px;
        @include laptop {
            display: flex;
            flex-direction: row;
            align-items: center;

            width: 49%;
            margin-bottom: 0;
        }

        // IMG
        img {
            @include supaclean;
            width: 100%;
            height: auto;

            box-shadow: 0 3px 20px rgba(0, 0, 0, 0.45);
            border-radius: 6px;
        }

    }
    .droite {
        @include laptop { width: 46%; }

        // TITRE
        .pg_titre {
            @extend .h2_like;
            margin: 35px 0 10px;

            @include laptop {
                margin: 30px 0;
                padding-bottom: 10px;
                background: url(../img/deco/underline-fat-green.svg) no-repeat left bottom / 100px 6px;
            }
        }

        // LIENS
        .liens {
            margin-top: 20px;
            > div {
                margin-top: 10px;
                &:first-child { margin-top: 0; }
            }
            a {
                @extend .btn_base;
                @extend .btn_base--green;
            }
        }
    }

    // INVERSE
    @include laptop {
        &.pg_image_texte--inverse {
            flex-direction: row-reverse;
        }
    }
}



/*** BLOCK TITRE TEXTE ***************************/
.pg_titre_txt {
    padding: 20px 5%;
    text-align: left;

    @include laptop {
      padding: 40px 0;
      text-align: center;
    }


    // TITRE
    .pg_titre {
        @extend .h2_like;
        @extend .center;
        @extend .souligne--fat;
        background: url(../img/deco/underline-fat-green.svg) no-repeat center bottom / 100px 5px;
        margin-bottom: 15px;
    }

    ul {
        text-align: left;
        max-width: 600px;

        li {

            // PUPUCE
            &:before {
                background: $couleur_1;
            }
        }
    }
}



/*** BLOCK LISTING RE ASSURANCE ******************/
.pg_listing_reassurances {
    padding: 30px 5%;
    @include laptop { padding: 60px 0; }

    // TITRE
    .pg_titre {
        @extend .h2_like;
        @extend .souligne--fat;
        @extend .center;
        background: url(../img/deco/underline-fat-green.svg) no-repeat center bottom / 100px 5px;
    }

    // CONTENT
    .pg_listing_reassurances_content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        margin-top: 40px;
    }

    // ITEM
    .pg_reassurance {
        @include clean;
        width: 40%;
        @include laptop { width: 18%; }

        // MARGIN TOP
        @include phonetabletonly {
            margin-top: 30px;
            &:nth-child(1),
            &:nth-child(2) { margin-top: 0; }
        }
        @include laptop {
            margin-top: 50px;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) { margin-top: 0; }
        }

        // MARGIN LEFT
        @include phonetabletonly {
            margin-left: 5%;
            &:nth-child(2n+1) { margin-left: 0; }
        }
        @include laptop {
            margin-left: 5%;
            &:nth-child(4n+1) { margin-left: 0; }
        }

        @include lineheight(18);
        text-align: center;

        //LIEN
        a {
            text-decoration: none;
        }

        // LOGO
        img { width: initial; }

        // TITRE
        .pg_titre {
            @include supaclean;
            color: $couleur_1;
            @include fontsize(14);
            font-weight: 700;
            text-transform: uppercase;
            background: none;
        }

        // DESC
        .pg_description {
            @include supaclean;
            color: $black;
            @include fontsize(13);
            font-weight: 500;
            font-style: italic;
        }
    }
}



/*** BLOCK IMG CENTRE ****************************/
.pg_img_centre {
    padding: 60px 5%;
    @include laptop { padding: 60px 0; }

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // CONTENT
    .pg_titre_txt {
        padding: 30px 5%;
        @include laptop { padding: 30px 60px; }
        max-width: 750px;

        background-color: $white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    // TITRE
    .pg_titre {
        //@extend .h2_like;
        @include clean;

        color: $couleur_1;
        font-family: $font-base;
        @include fontsize(16);
        @include lineheight(20);
        font-weight: 700;

        @include tablet {
          @include fontsize(26);
          @include lineheight(32);
        }
        @extend .center;
        padding-bottom: 10px;
        background: url(../img/deco/underline-fat-green.svg) no-repeat center bottom / 100px 5px;
        margin-bottom: 25px;
    }

    // DESC
    .pg_texte {
        p {
            margin-top: 15px;
            &:first-child { margin-top: 0; }
        }
    }
}



/*** BLOCK VIDEO *********************************/
.pg_video {
    display: flex;
    flex-direction: column;
    @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        //Si vidéo à droite
        &--inverse {
            .pg_video {
              flex-direction: row-reverse;
            }
        }
    }

    // MEDIA
    .pg_video_media {
        @include laptop { width: 700px; }

        // VIDEO RESPONSIVE
        .pg_video_wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    // CONTENT
    .pg_video_content {
        margin-top: 15px;
        @include laptop {
            width: calc(100% - 740px);
            text-align: left;
        }

        // TITRE
        .pg_video_titre {
            margin-bottom: 10px;
            @extend .h3_like;
            text-transform: uppercase;
            background-position: center bottom;

            @include laptop {
                padding-bottom: 10px;
                background: url(../img/deco/underline-fat-green.svg) no-repeat left bottom / 100px 6px;
            }
        }

        // SS TITRE
        .pg_video_sstitre {
            color: $green_light;
            @include fontsize(14);
            @include lineheight(20);
            font-weight: 700;
            font-style: italic;
            text-transform: uppercase;
        }

        // TEXTE
        .pg_video_desc {
            margin-top: 15px;
            @include laptop { margin-top: 20px; }
        }

        // LINK
        .pg_video_link {
            @extend .btn_base;
            @include fontsize(14);
            @include lineheight(16);
            margin-top: 20px;
            @include laptop { margin-top: 40px; }
        }
    }
}


/*** BLOCK PARAGRAPHE REFERENCE VERS DES PAGES *********************************/
.pg_references_page {
    padding: 0 0 40px;
    background: $couleur_2;

    @include laptop {
        padding: 60px 0 40px;
        background: url(../img/bg/champetre_vert_fat.jpg) no-repeat center;
        background-size: cover;
    }

    .main {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        @include laptop {
            max-width: 1000px;
        }
    }

    // ITEM
    .reference_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 40px;
        padding: 0 10px;
        width: 50%;

        @include laptop {
            width: 30%;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) { margin-top: 0; }
        }
        text-decoration: none;

        // ILLU
        .reference_item_illu {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            padding: 10px 11px;

            img {
                @include supaclean;
                position: relative;
                z-index: 10;
                width: 100%;
                max-width: 250px;
            }

            // DECO HEXA
            &:before {
                content: "";

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;

                width: 100%;
                height: 100%;
                background: url(../img/bg/hexa-white-fat.svg) no-repeat center;
                background-size: 100% auto;
            }

            // DECO PLUS
            &:after {
                opacity: 0;

                content: "";

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 20;

                width: 71px;
                height: 63px;
                background: url(../img/bt/plus-green.png) no-repeat left center;

                transition: $transition-base;
            }

            // HOVER
            &:hover {
                &:after { opacity: 1; }
            }
        }

        // LABEL
        .reference_item_label {
            width: 100%;
            color: $white;
            @include fontsize(13);
            @include lineheight(15);
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;

            @include laptop {
                @include fontsize(16);
                @include lineheight(20);
            }
        }
    }
}

.reference_disposition_four .pg_references_page {

  .main {
    @include laptop {
      max-width: 1200px;
    }
  }

  // ITEM
  .reference_item {
    @include laptop {
      width: 25%;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) { margin-top: 0; }
    }
  }
}


/*** POPIN ***/
.popin {
    &-overlay {
        display: none;

        &.active {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(0,0,0,.6);
            z-index: 100;
        }
    }

    &-content {
        display: none;

        .btn--close {
            content: "";

            position: absolute;
            top: 0;
            right: 0;

            width: 20px;
            height: 20px;

            background: url(../img/svg/close-white.svg) no-repeat center;
            background-size: 20px;
        }

        &.active {
            display: block;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 101;
            width: 90%;
            max-width: 1100px;
            padding-top: 30px;

            //scrollable if too tall
            overflow: auto;
            max-height: 80%;

            video {
                max-width: 100%;
            }

            .videoYt-container {
                position: relative;
                padding-bottom: 56.25%;
                padding-top: 30px;
                height: 0;
                overflow: hidden;
            }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

// Spécificité pour la pop-in page listing emplois
.popin-emploi-form.active {
    max-width: 400px;
    margin: 0 auto;

    .content {
        padding: 40px 20px;
        background: $white;
    }
}



/*** BREADCRUMB ABSOLUTE *************************/
@include laptop {
    .breadcrumbAbsolute {
        position: relative;

        .breadcrumb {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
        }

        // HISTORIQUE
        &.breadcrumbAbsolute--historique {
            ul {
                color: white;
                a,
                span {
                    &:hover { color: white; }
                }
            }
        }

        // PAGE HISTORIQUE
        .historiqueHeader { padding-top: 80px; }

        // PAGE ACTIVITE
        .zoneActivite { padding-top: 80px; }
    }
}
