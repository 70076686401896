/*** PAGE SEARCH ***********************************************************************/
.page_search {
    padding-top: 40px;

    // CONTENT
    .vue_base {
        display: flex;
        flex-direction: column;
        max-width: 980px;

        margin-top: 30px;
        @include laptop { margin-top: 50px; }
    }

    // ITEM
    .search-item {
        padding: 30px 20px;
        border-top: 1px solid rgba($couleur_1, .2);

        color: inherit;
        text-decoration: none;
        background: white;

        // ODD
        &:nth-child(odd) { background: #f9f9f9; }

        // TITRE
        .search-item--title { @extend .h3_like; }

        // TYPE
        .search-item--type { color: $green; }
    }
}
