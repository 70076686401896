/*** PAGE LISTING EMPLOI **********************************************************************/

/*** INTRO ****************************************/
.emplois_intro {

    @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    // CONTENT
    .emplois_intro_content {
        padding: 20px 5%;
        background: $blue;

        @include laptop {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            padding: 80px 60px 80px 0;
            width: 55%;

            background: url(../img/bg/hexa-triple.png) no-repeat right 620px center $blue;
        }

        // SPECIAL CONTENT
        .specialContent {
            @include laptop { width: 500px; }

            // TITRE
            .emplois_intro_titre {
                @extend .h1_like;
                @extend .souligne--white;
                margin-bottom: 15px;
                padding-bottom: 15px;
                color: $white;
                text-align: center;
                @include laptop { text-align: left; }

                background-position: center bottom;
                @include laptop { background-position: left 10px bottom; }
            }

            // TEXTE
            .emplois_intro_texte {
                color: $white;
                text-align: center;
                @include laptop { text-align: left; }

                p {
                    margin-top: 15px;
                    &:first-child { margin-top: 0; }
                }
            }
        }
    }

    // ILLU
    .emplois_intro_illu {
        @include phonetabletonly {
            display: flex;
            flex-direction: row;
            justify-content: center;
            background-color: white;
            background-image: none !important;
        }
        @include laptop {
            position: relative;
            width: 45%;

            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        // BOUTON
        .btn_base {
            border: 1px solid $blue;
            @include phonetabletonly { margin-top: 20px; }
            @include laptop {
                position: absolute;
                left: 200px;
                bottom: 20px;
            }
        }
    }
}



/*** LISTING **************************************/
.emplois_listing {
    margin: 30px 0 40px 0;
    @include laptop { margin: 60px 0 80px 0; }

    .main { max-width: 1000px; }

    // TITRE
    .emplois_listing_titre {
        @extend .h2_like;
        @extend .center;
        margin-bottom: 35px;
        padding-bottom: 10px;

        // TIRET
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            margin-left: -20px;
            bottom: 0;

            width: 40px;
            height: 1px;
            background: $blue;
        }
    }
}

.offres-emploi-listing-wrapper,
.offres-emploi-autres {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;


    // ITEM
    .emplois_item {
        width: 100%;
        @include laptop { width: 30%; }
        text-decoration: none;

        // TOP
        @include phonetabletonly {
            margin-top: 30px;
            &:first-child { margin-top: 0; }
        }
        @include laptop {
            margin-top: 40px;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) { margin-top: 0; }
        }

        // LEFT
        @include laptop {
            margin-left: 5%;
            &:nth-child(3n+1) { margin-left: 0; }
        }

        background: $grey_light;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        transition: $transition-base;

        // HOVER
        @include laptop {
            &:hover {
                box-shadow: 0 3px 20px rgba(0, 0, 0, 0.45);
                .views-field-title { color: $blue; }
            }
        }

        // TITRE
        .emplois_item_titre {
            padding: 15px 30px;
            color: $blue;
            @include fontsize(18);
            @include lineheight(18);
            font-weight: 500;
            background: $white;

            // PICTO
            span {
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: -20px;

                    width: 14px;
                    height: 14px;

                    background: url(../img/picto/hexa-blue.svg) no-repeat left center / 14px auto;
                }
            }
        }

        // DESC
        .emplois_item_desc {
            padding: 20px 30px;
            color: $grey;
            @include fontsize(14);
            @include lineheight(14);
            font-weight: 700;

            div {
                margin-top: 10px;
                &:first-child { margin-top: 0; }
            }

            i {
                @include fontsize(12);
                font-weight: 500;
            }
        }
    }
}


/*** LISTING AUTRES *******************************/
.offres-emploi-autres { margin-top: 40px; }


/*** LISTING EMPTY ********************************/
.offres-emploi-listing-empty {
    color: $grey;
    @include fontsize(24);
    text-align: center;
}



/*** PAGE DETAILS EMPLOI **********************************************************************/
.detailsEmplois {
    padding: 40px 0;

    // TITRE
    .detailsEmplois_titre {
        margin-bottom: 30px;

        color: #2f2f2f;
        @include fontsize(26);
        font-weight: 700;
    }

    // CONTENT
    .detailsEmplois_content {
        @include laptop {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        // DESC
        @include laptop {
            .detailsEmplois_desc { width: calc(100% - 420px); }
        }

        // SIDEBAR
        @include laptop {
            .detailsEmplois_sidebar { width: 300px; }
        }
    }

    // CARD
    .detailsEmplois_card {
        @include laptop {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

        // INFO
        .detailsEmplois_card_info {
            @include laptop { width: 300px; }

            // TITRE
            .detailsEmplois_card_titre {
                padding: 15px 30px;
                color: $blue;
                @include fontsize(18);
                @include lineheight(18);
                font-weight: 500;
                background: $white;

                // PICTO
                span {
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        margin-top: -7px;
                        left: -20px;

                        width: 14px;
                        height: 14px;

                        background: url(../img/picto/hexa-blue.svg) no-repeat left center / 14px auto;
                    }
                }
            }

            // DESC
            .detailsEmplois_card_desc {
                padding: 20px 30px;
                color: $grey;
                @include fontsize(14);
                @include lineheight(14);
                font-weight: 700;
                background: $grey_light;

                div {
                    margin-top: 10px;
                    &:first-child { margin-top: 0; }
                }

                i {
                    @include fontsize(12);
                    font-weight: 500;
                }
            }
        }

        // ILLU
        .detailsEmplois_card_illu {
            @include phonetabletonly { display: none; }
            @include laptop {
                width: calc(100% - 300px);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }


    // INFO
    .detailsEmplois_info {
        margin-top: 40px;

        // INTRO
        .detailsEmplois_info_intro { font-style: italic; }

        // ITEM
        .detailsEmplois_info_item {
            margin-top: 30px;
            &:first-child { margin-top: 0; }

            // TITRE
            .detailsEmplois_info_titre {
                margin-bottom: 10px;
                padding-left: 20px;

                @include fontsize(14);
                font-weight: 700;
                text-transform: uppercase;

                background: url(../img/picto/pupuce-hexa.svg) no-repeat left center / 14px auto;
            }
        }
    }


    // SIDEBAR
    .detailsEmplois_sidebar {
        @include phonetabletonly { margin-top: 40px; }
    }
}

// FORM sur pages détail emploi et listing emploi (candidature spontanée)
// FORM
.detailsEmplois_form {
    padding: 35px 20px;
    background: $blue;

    //  TITRE
    .detailsEmplois_form_titre {
        margin-bottom: 10px;

        color: $white;
        @include fontsize(22);
        font-weight: 600;
        text-align: center;
    }

    // FORM
    form {
        color: $white;

        // ITEM
        .form-item {
            margin-top: 15px;
            &:first-child { margin-top: 0; }

            // INPUT
            input {
                color: $grey;
                @include fontsize(12);
                font-weight: 500;
                font-style: italic;

                &::placeholder { color: $grey; }
            }
        }

        .form-item-votre-cv a, .form-item-votre-lettre-de-motivation a {
            color: $white;
        }

        // CHAMPS FILE
        #ajax-wrapper,
        #ajax-wrapper--2 { margin-top: 15px; }

        // SUBMIT
        .form-actions {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .form-submit {
                margin: 30px 0 0 0;
                padding: 10px 35px;

                color: $white;
                @include fontsize(18);
                font-weight: 500;

                background: $red;
                border: none;
                border-radius: 6px;

                cursor: pointer;
            }
        }
    }

    .webform-confirmation__message {
        margin-top: 35px;
        color: $white;
        text-align: center;
    }
}

// ML
.detailsEmplois_sidebar_ml {
    margin-top: 40px;
    @include fontsize(12);
    font-weight: 400;
}
