//vues de base du site
/*
.vue_base {
    .pager {
        width: 100%;
    }

    @include tablet {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}
*/


//Titre des vues
.titre--vue {
    display: block;
    margin: 20px auto 10px auto;
    @include uppercolor($black, 24);
    font-weight: bold;
    text-align: center;
}

.vue_articles {
    margin: 50px auto;

    .pager {
        display: block;
        width: 100%;
    }

    @include tablet {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 1024px;
    }

    @include laptop {}
}

.article_teaser {
    display: block;
    width: 300px;
    margin: 0 auto 30px auto;
    @include lazylinks($black);
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 0px 4px 1px #ececec;

    .contenu {
        padding: 20px;
    }

    //Titre
    .titre--teaser {
        display: block;
        margin: 0 auto 10px auto;
        @include fontsize(18);
        font-weight: bold;
    }
    //Titre avec lien
    .article_teaser_title {
        a {
            display: block;
            margin: 0 auto 10px auto;
            @include fontsize(18);
            font-weight: bold;
            text-decoration: none;
            @include links($couleur-1, $black, $couleur-1, $black, $black);
        }
    }
    .lire_plus {
        display: block;
        margin-top: 10px;
        text-decoration: underline;
    }
}

// Vue résultat recherche
// Page rehcerhce
.vue_recherche {
    padding-top: 30px;

    .resultat {
        width: 100%;
        padding: 20px;

        .lien {
            bottom: -20px;
        }

        &:hover {
            .lien {
                height: calc(100% + 20px);
            }
        }
    }


    .ctn_infos {
        width: 100%;
    }

    @include tablet {
        width: 100%;

        .resultat {
            width: 48%;
        }
    }

    @include laptop {
        max-width: 1320px;
        margin: 0 auto;
        min-height: 100px;

        .resultat {
            width: 30%;
            margin: 0 auto 20px auto;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
        }
    }
}
