.js_pageLogin_btn a {
  padding: 10px 25px;

  color: $white;
  font-family: $font-base;
  @include fontsize(14);
  @include lineheight(14);
  @include laptop {
    @include fontsize(18);
    @include lineheight(18);
  }
  font-weight: 400;
  text-decoration: none;

  background: $couleur_1;
  border: 1px solid $couleur_1;
  border-radius: 12px;

  // HOVER
  @include laptop {
    transition: $transition-base;

    &:hover {
      color: $couleur_1;
      background-color: $white;
    }
  }
}

.pageLogin_logo >a, .pageLogin_form input[type=submit] {
  background: $couleur_1;
}

.pageLogin_form label, .pageLogin_form ul li a {
  color: $couleur_1;
}
