/*** PAGE CONTACT *****************************************************************************/

/*** INTRO ****************************************/
.contact_intro {
    padding: 40px 0;
    text-align: center;

    // TITRE
    .contact_intro_titre {
        @extend .h1_like;
        @extend .center;
        @extend .souligne--fat;
        margin-bottom: 15px;
    }

    // DESC
    .contact_intro_desc {
        margin: 0 auto;
        max-width: 1000px;
    }
}


/*** CONTENT **************************************/
.contact_content {
    @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        margin-top: 50px;
    }

    padding: 30px 20px;

    background: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

@include laptop {
    .contact_info { width: 250px; }
}

// PHONE
.contact_phone {
    padding: 25px 25px;

    color: $white;
    @include fontsize(13);
    @include lineheight(16);
    font-weight: 400;

    background: $couleur_2;
}

// ADDRESS
.contact_address {
    margin-top: 20px;
    padding: 25px 25px;

    color: $white;
    @include fontsize(14);
    @include lineheight(18);
    font-weight: 400;

    background: $couleur_1;
}

// TITRE
.contact_titre {
    margin-bottom: 10px;

    color: $white;
    @include fontsize(26);
    @include lineheight(26);
    font-weight: 500;
}

// SS TITRE
.contact_sstitre {
    margin-bottom: 15px;
    padding-bottom: 15px;

    color: $white;
    @include fontsize(22);
    @include lineheight(22);
    font-weight: 500;


    background: url(../img/deco/underline-white.svg) no-repeat left bottom / 80px 5px;
}

//FORM
@include laptop {
    .contact_form { width: calc(100% - 300px); }
}


/*** BROCHURES ***/
.contact_brochure {
  width: 100%;
  margin-top: 80px;

  // TITRE
  .h2_like { margin-bottom: 40px; }

  // WRAPPER
  &_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px 20px;
    @include tablet { gap: 25px 50px; }
  }

  // ITEM
  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc( (100% - 20px) / 2 );
    @include tablet { width: 180px; }
    @include tablet { padding: 10px; }
    border-radius: 20px;

    // RESET LINK
    color: inherit;
    text-decoration: none;

    // HOVER
    transition: $transition-base;
    &:hover { background-color: #f7f7f7; }
  }

  // ICON
  &_icon {
    display: block;
    max-width: 80px;
    margin: 0 0 15px 0;
  }

  // LABEL
  &_label {
    color: $couleur_2;
    font-family: $font-base;
    @include fontsize(14);
    @include lineheight(18);
    font-weight: 700;
    text-align: center;
  }
}


/*** MAP ******************************************/
.contact_map {
    margin-top: 70px;
    text-align: center;

    // TITRE
    .contact_map_titre {
        @extend .h2_like;
        @extend .center;
        @extend .souligne--fat;
        margin-bottom: 15px;
        background-position: bottom center;

        // DECO MARKER
        span {
            position: relative;
        }
    }

    // IMG
    .contact_map_img {
        display: flex;
        flex-direction: row;
        justify-content: center;

        img {
            @include supaclean;
            margin: 0;
            padding: 0;
        }
    }

    .contact_map_js {
        height: 400px;
        border: 7px $white solid;
    }

    .etablissement_data {
      display: none;
    }
}
