/*** PAGE BASIQUE *****************************************************************************/
.pageBasique {
    padding: 40px 0;

    // TITRE
    .pageBasique_titre {
        @extend .h1_like;
        @extend .center;
        @extend .souligne--fat;

        margin-bottom: 40px;
    }

    // CONTENT
    .pageBasique_content {
        text-align: center;

        p {
            margin-top: 15px;
            &:first-child { margin-top: 0; }
        }

        ul {
            text-align: left;
            max-width: 600px;
            margin: 15px auto;

            li {
                position: relative;
                padding-left: 15px;

                // PUPUCE
                &:before {
                    content: "";

                    position: absolute;
                    top: 8px;
                    left: 0;

                    width: 6px;
                    height: 6px;

                    background: $couleur_2;
                }
            }
        }
    }
}
