/*** PAGE THEMATIQUE **************************************************************************/


/*** INTRO ****************************************/
.thematique_intro {
    margin: 0 auto;
    padding: 40px 0;
    max-width: 800px;
    text-align: center;

    .thematique_titre {
        @extend .h2_like;
        @include fontsize(22);
        @include lineheight(26);
        @extend .souligne--fat;
        background: url(../img/deco/underline-fat-green.svg) no-repeat center bottom / 100px 5px;
        margin-bottom: 25px;

        @include tablet {
          @include fontsize(26);
          @include lineheight(32);
        }
    }

    .thematique_content {
        @include fontsize(14);
        @include lineheight(20);
    }

    .btn_base { margin-top: 40px; }
}
