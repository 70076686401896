/*** FORM *************************************************************************************/
form {

    // INPUT TYPE
    select,
    textarea,
    input[type="email"],
    input[type="tel"],
    input[type="text"] {
        width: 100%;
        height: 40px;
        padding: 0 10px;

        color: black;
        font-family: $font-base;
        @include fontsize(12);
        @include lineheight(12);
        font-weight: 400;

        &::placeholder { color: $couleur_1; }

    }

    textarea {
        height: 150px;
        padding: 10px;
    }

    // SUBMIT
    /*
    input[type="submit"] {
        @extend .btn_base;
        @include laptop {
            cursor: pointer;
            transition: $transition-base;

            &:hover {
                background: white;
            }
        }
    }
    */
}


/*** FORM SEARCH ***********************************/
.formSearch {
    padding: 20px 10%;
    @include laptop { padding: 0; }

    form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 100%;

        // INPUT
        .form-item {
            width: 80%;
            height: 40px;

            input {
                padding: 0 10px;

                color: $couleur_2;
                @include fontsize(12);
                @include lineheight(12);
                font-weight: 500;

                border-top: none;
                border-bottom: 1px solid $couleur_2;
                border-left: none;
                border-right: none;

                &::placeholder { color: $couleur_2; }
            }
        }

        // SUBMIT
        .form-actions {
            width: 20%;
            height: 40px;

            input {
                width: 100%;
                height: 40px;

                background: url(../img/picto/loupe-green.svg) no-repeat center / 20px auto;

                // RESET
                border: none;
                font-size: 0;
                cursor: pointer;
            }
        }
    }

    // RESET
    h2 { display: none; }
}



/*** FORM CONTACT **********************************/
.contact_form {
    @include phonetabletonly { margin-top: 40px; }

    // FORM ITEM
    .form-item {
        @include phonetabletonly {
            margin-top: 20px;
        }

        // LABEL
        label {
            @include supaclean;
            margin-bottom: 10px;

            color: $black;
            @include fontsize(12);
            @include lineheight(12);
            font-weight: 500;
            font-style: italic;
        }
    }

    // TRIPLE BLOCK
    .formContact_tripleBlock {
        @include laptop {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        margin-top: 25px;
        &:first-child { margin-top: 0; }

        // ITEM
        @include laptop {
            .form-item { width: 30%; }
        }
    }

    // DOUBLE BLOCK
    .formContact_doubleBlock {
        margin-top: 25px;

        @include laptop {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    // COLUMN BLOCK
    .formContact_columnBlock  {
        @include laptop { width: 30%; }

        // ITEM
        .form-item {
            margin-top: 25px;
            &:first-child { margin-top: 0; }
            width: 100%;
        }
    }

    // MESSAGE
    .form-item-message {
        @include laptop { width: 65%; }
        textarea { height: 129px; }
    }

    // CHECKBOX
    .js-form-type-checkbox {
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        input {
            margin: 2px 10px 0 0;
            width: 20px;
            height: 20px;
        }
        label {
            margin-bottom: 10px;
            width: calc(100% - 30px);
            @include lineheight(18);
        }
    }

    // SUBMIT
    .form-actions {
        display: flex;
        flex-direction: row;
        justify-content: center;

        margin-top: 30px;

        input {
            margin: 0;
            padding: 10px 20px;

            color: $white;
            @include fontsize(18);
            font-weight: 500;

            background-color: $couleur_3;
            border: none;
            border-radius: 6px;

            cursor: pointer;
        }
    }

    .webform-confirmation__message {
        color: $couleur_1;
        text-align: center;
    }
}

.contact_form_rgpd {
  margin-top: 30px;
  @include fontsize(13);
}


/*** FORM EMPLOI FILTERS ***************************/
.emplois_listing {
    form {
        display: flex;
        margin-bottom: 30px;
        @include phonetabletonly {
            flex-direction: column;
            align-items: center;
        }
        @include laptop {
            flex-direction: row;
            justify-content: center;
            margin-bottom: 50px;
        }

        // SELECT
        .form-item {
            @include phonetabletonly {
                margin-top: 10px;
                &:first-child { margin-top: 0; }
                width: 100%;
            }
            @include laptop {
                margin-left: 10px;
                &:first-child { margin-left: 0; }
            }
            select {
                @include laptop { width: 250px; }
                color: $grey;
                @include fontsize(12);
                font-weight: 500;
                font-style: italic;
                background: white;
            }
        }

        // SUBMIT
        .form-actions {
            @include phonetabletonly { margin-top: 10px; }
            @include laptop { margin: 0 0 0 20px; }

            input {
                padding: 10px 20px;

                color: $white;
                @include fontsize(16);
                font-weight: 500;

                background-color: $couleur_1;
                border: none;
                border-radius: 6px;

                cursor: pointer;
            }
        }
    }
}



/*** FORM NEW ACCOUNT **********************************/
.page-user-register {
  margin-top: 60px;
}

#user-register-form {
  margin: 0;
  padding: 0 5%;

  @include laptop {
    max-width: 800px;
    margin: 60px auto 0;
    padding: 0;
  }

  div {
    margin-bottom: 15px;
  }

  > div, details {
    margin-bottom: 25px;
  }

  input[type=submit] {
    display: inline-block;
    cursor: pointer;
    padding: 10px 25px;

    color: $white;
    font-family: $font-base;
    @include fontsize(14);
    @include lineheight(14);
    @include laptop {
      @include fontsize(18);
      @include lineheight(18);
    }
    font-weight: 400;
    text-decoration: none;

    background: $couleur_1;
    border: 1px solid $couleur_1;
    border-radius: 12px;

    // HOVER
    @include laptop {
      transition: $transition-base;

      &:hover {
        color: $couleur_1;
        background-color: $white;
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}



/*** FORM LOST PWD **********************************/
.page-user-password {
  margin-top: 60px;
}

#user-pass {
  margin: 0;
  padding: 0 5%;

  @include laptop {
    max-width: 800px;
    margin: 60px auto 0;
    padding: 0;
  }

  input[type=submit] {
    display: inline-block;
    cursor: pointer;
    padding: 10px 25px;

    color: $white;
    font-family: $font-base;
    @include fontsize(14);
    @include lineheight(14);
    @include laptop {
      @include fontsize(18);
      @include lineheight(18);
    }
    font-weight: 400;
    text-decoration: none;

    background: $couleur_1;
    border: 1px solid $couleur_1;
    border-radius: 12px;

    // HOVER
    @include laptop {
      transition: $transition-base;

      &:hover {
        color: $couleur_1;
        background-color: $white;
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}


/*** FORM EDIT ACCOUNT **********************************/
.page-user-edit {
  margin-top: 60px;
}

#user-form {
  margin: 0;
  padding: 0 5%;

  @include laptop {
    max-width: 800px;
    margin: 60px auto 0;
    padding: 0;
  }

  div {
    margin-bottom: 15px;
  }

  > div, details {
    margin-bottom: 25px;
  }

  input[type=submit] {
    display: inline-block;
    cursor: pointer;
    padding: 10px 25px;

    color: $white;
    font-family: $font-base;
    @include fontsize(14);
    @include lineheight(14);
    @include laptop {
      @include fontsize(18);
      @include lineheight(18);
    }
    font-weight: 400;
    text-decoration: none;

    background: $couleur_1;
    border: 1px solid $couleur_1;
    border-radius: 12px;

    // HOVER
    @include laptop {
      transition: $transition-base;

      &:hover {
        color: $couleur_1;
        background-color: $white;
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}


/*** FORM RESET PWD **********************************/
.page-user-reset {
  margin-top: 60px;
}

#user-pass-reset {
  margin: 0;
  padding: 0 5%;

  @include laptop {
    max-width: 800px;
    margin: 60px auto 0;
    padding: 0;
  }

  input[type=submit] {
    display: inline-block;
    cursor: pointer;
    padding: 10px 25px;

    color: $white;
    font-family: $font-base;
    @include fontsize(14);
    @include lineheight(14);
    @include laptop {
      @include fontsize(18);
      @include lineheight(18);
    }
    font-weight: 400;
    text-decoration: none;

    background: $couleur_1;
    border: 1px solid $couleur_1;
    border-radius: 12px;

    // HOVER
    @include laptop {
      transition: $transition-base;

      &:hover {
        color: $couleur_1;
        background-color: $white;
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}



.webform-confirmation__back {
  display: flex;
  justify-content: center;

  a {
    display: inline-block;
    margin-top: 15px;
    padding: 15px 30px;

    color: $couleur_1;
    @include fontsize(16);
    font-weight: 600;
    text-align: center;
    text-decoration: none;

    background: $grey_light;

    // HOVER
    @include laptop {
      transition: $transition-base;
      &:hover {
        color: $white;
        background: $couleur_1;
      }
    }
  }
}
