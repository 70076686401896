/** THEME ************************************************************************************/

/*** PALETTE *************************************/
$black: #000000;
$blue: #004289;
$green: #76b72a;
$green_light: #a3bf56;
$grey: #707070;
$grey_light: #f4f4f4;
$red: #e42313;
$white: #ffffff;

/*** COULEURS DE BASE *****************************/
$couleur_1 : #3e7651;
$couleur_2 : #b4c30d;
$couleur_3 : #75b323;


/*** FONTS *************************************************************************************/
$font-base: 'Montserrat', sans-serif;


// H1
.h1_like {
    @include clean;

    color: $couleur_1;
    font-family: $font-base;
    @include fontsize(30);
    @include lineheight(36);
    font-weight: 700;

    @include tablet {
        @include fontsize(32);
        @include lineheight(38);
    }
}

// H2
.h2_like {
    @include clean;

    color: $couleur_1;
    font-family: $font-base;
    @include fontsize(16);
    @include lineheight(20);
    font-weight: 700;

    @include tablet {
        @include fontsize(26);
        @include lineheight(32);
    }
}

// H3
.h3_like {
    @include clean;

    color: $couleur_1;
    font-family: $font-base;
    @include fontsize(16);
    @include lineheight(22);
    font-weight: 700;

    @include tablet {
        @include fontsize(16);
        @include lineheight(20);
    }
}

// CENTER
.h1_like,
.h2_like,
.h3_like {
    &.center { text-align: center; }
}

// SOULIGNE
.h1_like,
.h2_like,
.h3_like {
    &.white { color: $white; }
    &.souligne {
        padding-bottom: 10px;
        background: url(../img/deco/underline-blue.svg) no-repeat left 10px bottom / 60px 5px;
        &.center { background: url(../img/deco/underline-blue.svg) no-repeat center bottom / 60px 5px; }
    }
    &.souligne--fat {
        padding-bottom: 10px;
        background: url(../img/deco/underline-fat-green.svg) no-repeat left 10px bottom / 100px 6px;
        &.center { background: url(../img/deco/underline-fat-green.svg) no-repeat center bottom / 100px 5px; }
    }
    &.souligne--white {
        padding-bottom: 10px;
        background: url(../img/deco/underline-fat-white.svg) no-repeat left bottom / 80px 5px;
        &.center { background: url(../img/deco/underline-fat-white.svg) no-repeat center bottom / 80px 5px; }
    }
    &.souligne--red {
        padding-bottom: 10px;
        background: url(../img/deco/underline-red.svg) no-repeat left bottom / 80px 5px;
        &.right {
            text-align: right;
            background: url(../img/deco/underline-red.svg) no-repeat right bottom / 80px 5px;
        }
    }
}



/*** CLASSICO **********************************************************************************/
body {
    color: $black;
    font-family: $font-base;
    @include fontsize(15);
    @include lineheight(20);
    font-weight: 400;
}

// LIENS
a {
  color: inherit;
}

// BOUTON BASE
.btn_base  {
    display: inline-block;
    padding: 10px 25px;

    color: $white;
    font-family: $font-base;
    @include fontsize(14);
    @include lineheight(14);
    @include laptop {
        @include fontsize(18);
        @include lineheight(18);
    }
    font-weight: 400;
    text-decoration: none;

    background: $couleur_3;
    border: 1px solid $couleur_3;
    border-radius: 6px;

    // HOVER
    @include laptop {
        transition: $transition-base;

        &:hover {
            color: $couleur_3;
            background-color: $white;
        }
    }

    // GREEN
    &.btn_base--green {
        background: $green;
        border: 1px solid $green;

        // HOVER
        @include laptop {
            &:hover { color: $green; }
        }
    }

    // WHITE
    &.btn_base--white {
        color: $couleur_1;
        background: $white;
        border: 1px solid $white;

        // HOVER
        @include laptop {
            &:hover {
                color: $white;
                background: $couleur_1;
            }
        }
    }
}


/*** LISTE BASIC ***********************************/
.paragraph {
    ul {
        margin: 15px 0;

        li {
            position: relative;
            padding-left: 15px;

            // PUPUCE
            &:before {
                content: "";

                position: absolute;
                top: 8px;
                left: 0;

                width: 6px;
                height: 6px;

                background: $white;
            }
        }
    }

    &.pg_image_texte {
        ul {
            li {
                &:before { background: $couleur_2; }
            }
        }

    }
}
