/*** PAGE ZONE ACTIVITE ***********************************************************************/

/*** LISTING **************************************/
.page_actualites {
    padding-top: 40px;

    // CONTENT
    .listing-actus {
        margin-top: 30px;
        @include laptop { margin-top: 50px; }

        display: flex;
        flex-direction: column;
        @include laptop {
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    // ITEM
    .item_actu {
        @include laptop { width: 31%; }

        margin-top: 40px;
        &:nth-child(1) { margin-top: 0; }

        @include laptop {
            &:nth-child(2),
            &:nth-child(3) { margin-top: 0; }

            margin-left: 3%;
            &:nth-child(3n+1) { margin-left: 0; }
        }
    }

    .pager__item {
        &.is-active {
            color: $couleur_1;
            font-weight: 600;
        }


        &.pager__item--last a,
        &.pager__item--first a {
            background: url(../img/bt/chevron-double-vert.svg) no-repeat center/10px auto;
        }

        &.pager__item--next a,
        &.pager__item--previous a {
            background: url(../img/bt/chevron-vert.svg) no-repeat center/10px auto;
        }
    }
}


/*** DETAILS **************************************/
.page_actualite {

    // ACTU DETAILS
    .actuDetails {
        @include laptop {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-bottom: 40px;
        }
    }

    // DESC
    .actuDetails_desc {
        padding: 20px 5%;
        background: $couleur_1;

        @include laptop {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            padding: 80px 60px 80px 0;
            width: 55%;

            background: url(../img/bg/hexa-triple-green.png) no-repeat right 620px center $couleur_1;
        }

        // SPECIAL CONTENT
        .specialContent {
            @include laptop { width: 500px; }

            // TITRE
            .actuDetails_desc_titre {
                @extend .h3_like;
                @extend .souligne--white;
                margin-bottom: 20px;
                padding-bottom: 15px;
                color: $white;
                text-transform: uppercase;

                // RESET
                text-align: left !important;
                background-position: left bottom !important;
            }

            // CATEG
            .actuDetails_desc_categ { color: $white; }

            // DATE
            .actuDetails_desc_date {
                margin-bottom: 5px;
                color: $couleur_2;
                font-weight: 700;
            }

            // TEXTE
            .actuDetails_desc_texte {
                color: $white;
                text-align: left;
                p {
                    margin-top: 15px;
                    &:first-child { margin-top: 0; }
                }

                ul {
                   margin: 15px 0;

                    li {
                        position: relative;
                        padding-left: 15px;

                        // PUPUCE
                        &:before {
                            content: "";

                            position: absolute;
                            top: 8px;
                            left: 0;

                            width: 6px;
                            height: 6px;

                            background: $couleur_2;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }

    // ILLU
    .actuDetails_illu {

      width: 100%;
      height: 300px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include laptop {
        display: flex;
        width: 45%;
        height: auto;
        min-height: 300px;
      }
    }
}
