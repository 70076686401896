/*** PAGE PRESENTATION ************************************************************************/
.page_presentation {

    // RE-ASSURANCES
    .pg_listing_reassurances { padding-bottom: 0; }
}

// BLOCK PRESENTATION
.presentation {

    display: flex;
    flex-direction: column-reverse;

    @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: center;

        padding-bottom: 40px;
    }

    // DESC
    .presentation_desc {
        padding: 20px 5%;
        background: $blue;

        @include laptop {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            padding: 80px 60px 80px 0;
            width: 55%;

            background: url(../img/bg/hexa-triple.png) no-repeat right 620px center $blue;
        }

        ul {
            margin: 15px 0;

            li {
                position: relative;
                padding-left: 15px;

                // PUPUCE
                &:before {
                    content: "";

                    position: absolute;
                    top: 8px;
                    left: 0;

                    width: 6px;
                    height: 6px;

                    background: $white;
              }
            }
        }
    }

    // SPECIAL CONTENT
    .specialContent {
        @include laptop { width: 500px; }

        // TITRE
        .pg_titre {
            margin-bottom: 20px;

            @extend .h2_like;
            @extend .souligne--white;
            color: $white;
            text-align: left;

            p {
                @extend .h2_like;
                color: $white;
            }
        }

        // TEXTE
        .pg_texte {
            color: $white;
            text-align: left;
            p {
                margin-top: 15px;
                &:first-child { margin-top: 0; }
            }
        }
    }

    // LIENS
    .liens {
        margin-top: 30px;
        @extend .btn_base;
        @extend .btn_base--white;
    }

    // ILLU
    .presentation_illu {

        width: 100%;
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include laptop {
            display: flex;
            width: 45%;
            height: auto;
        }

        // IMG
        //img {
        //    @include supaclean;
        //    object-fit: cover;
        //}
    }
}
