/*** PAGE USINE ************************************************************************/

/*** LISTES *******************/
.usineHeader, .usineIntro {
  ul {
    text-align: left;
    max-width: 600px;
    margin: 15px auto;

    li {
      position: relative;
      padding-left: 15px;
      color: $black;

      // PUPUCE
      &:before {
        content: "";

        position: absolute;
        top: 8px;
        left: 0;

        width: 6px;
        height: 6px;

        background: $couleur_2;
      }
    }
  }
}

/*** HEADER *******************/
.usineHeader {
  box-shadow: 0 8px 8px -6px rgba(0, 0, 0, 0.1);

  @include laptop {
    display: flex;
    align-items: flex-end;
    min-height: 480px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
    box-shadow: none;
  }

  &_img {
    display: block;
    background: $white;

    img {
      display: block;
      margin: 0 auto;
    }

    @include laptop {
      display: none;
    }
  }

  &_cartouche {
    padding: 40px 5%;
    background: $white;

    @include laptop {
      max-width: 600px;
      margin: 0 auto;
      padding: 25px;
      background: rgba(255,255,255,0.8);
    }
  }

  &_titre {
    @extend .h2_like;
    @extend .souligne--fat;
    @include fontsize(22);
    @include lineheight(26);
    text-align: center;
    background: url(../img/deco/underline-fat-green.svg) no-repeat center bottom / 100px 5px;
    margin-bottom: 25px;

    @include tablet {
      @include fontsize(26);
      @include lineheight(32);
    }
  }

  &_texte {
    text-align: center;
  }

  ul {
    max-width: 450px;
  }
}

/*** HEADER *******************/
.usineIntro {
  margin: 30px auto 20px;
  padding: 0 5%;
  text-align: center;

  @include laptop {
    max-width: 850px;
    padding: 0 20px;
  }
}


