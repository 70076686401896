/*** PAGE ENTREPRISE ************************************************************************/
.page_entreprise {
  &.bg_champetre {
    @include phoneonly {
      background: none;
      padding-bottom: 30px;
    }
  }
}

/*** HEADER *******************/
.entrepriseHeader {
  padding: 20px 5%;
  @include laptop { padding: 80px 0; }

  // MAIN
  .main {
    @include laptop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  // ILLU
  .entrepriseHeader_illu {
    @include phonetabletonly { margin-bottom: 30px; }
    @include laptop {
      width: 48%;
      text-align: right;
    }

    // IMG
    img {
      @include clean;
      max-width: 100%;
      height: auto;
    }
  }

  // DESC
  .entrepriseHeader_content {
    @include laptop { width: 48%; }
  }

  // TITRE
  .entrepriseHeader_titre {
    @extend .h2_like;
    @extend .souligne--fat;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  // DESC
  .entrepriseHeader_desc {
    p {
      margin-top: 15px;
      &:first-child { margin-top: 0; }
    }
  }

  // LOGOS
  .entrepriseHeader_logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 25px;
  }

  .entrepriseHeader_logo {
    max-width: 100px;
    padding: 0 12px;

    &:first-child { padding-left: 0; }
  }

  ul {
    margin: 15px 0;

    li {
      position: relative;
      padding-left: 15px;

      // PUPUCE
      &:before {
        content: "";

        position: absolute;
        top: 8px;
        left: 0;

        width: 6px;
        height: 6px;

        background: $blue;
        border-radius: 50%;
      }
    }
  }
}

/*** CONTENUS *******************/

.entreprise {

  .pg_references_page {
    margin-bottom: 20px;

    @include laptop {
      margin-bottom: 80px;
    }
  }
}

