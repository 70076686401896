/*** PAGE HISTORIQUE **************************************************************************/

/*** DATES ****************************************/
.historiqueDate {
    padding: 50px 0;

    // ITEM
    .historiqueDate_item {
        display: flex;
        flex-direction: column;
        @include laptop {
            flex-direction: row;
            justify-content: center;
        }

        position: relative;
        padding: 30px 20px 30px 70px;
        @include laptop { padding: 30px 100px; }

        // ILLU
        .historiqueDate_illu {
            @include phonetabletonly { margin-bottom: 30px; }
            @include laptop {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                width: 50%;
            }

            img { @include supaclean; }
        }

        // CONTENT
        .historiqueDate_content {
            @include laptop { width: 50%; }
        }

        // TITRE
        .historiqueDate_desc_titre {
            @extend .h2_like;
            padding-bottom: 10px;
            background: url(../img/deco/underline-fat-green.svg) no-repeat left bottom / 80px 5px;
            margin-bottom: 20px;
        }

        .historiqueDate_desc_sstitre {
            margin-bottom: 20px;
            color: $couleur_2;
        }

        // DESC
        .historiqueDate_desc { overflow: hidden; }

        // EN SAVOIR PLUS
        .historiqueDate_desc_plus {
            margin-top: 20px;
            color: $couleur_1;
            text-decoration: underline;
            cursor: pointer;
        }

        // LINE
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            @include phonetabletonly { left: 22px; }
            width: 1px;
            height: 100%;

            background: $couleur_1;
        }

        // BEULETTE
        .historiqueDate_item_deco {
            position: absolute;
            top: 30px;
            left: 0;
            @include laptop {
                left: 50%;
                margin-left: -22px;
            }

            width: 44px;
            height: 44px;

            background: $couleur_2;
            border: 1px solid $couleur_1;
            border-radius: 50%;

            // BEULETTE INTERNE
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                margin-top: -8px;
                left: 50%;
                margin-left: -8px;

                width: 16px;
                height: 16px;

                background: #fff200;
                border-radius: 50%;
            }
        }

        // DATE "PAIRE"
        @include laptop {
            &:nth-child(odd) {
                flex-direction: row-reverse;
                .historiqueDate_illu { padding-left: 70px; }
                .historiqueDate_content {
                    justify-content: flex-end;
                    padding-right: 70px;
                    text-align: right;
                }
                .historiqueDate_desc_titre {
                    background: url(../img/deco/underline-fat-green.svg) no-repeat right bottom / 80px 5px;
                }
            }
        }

        // DATE "IMPAIRE"
        @include laptop {
            &:nth-child(even) {
                background: $grey_light;
                .historiqueDate_illu {
                    justify-content: flex-end;
                    padding-right: 70px;
                }
                .historiqueDate_content { padding-left: 70px; }
            }
        }

        // FIRST CHILD
        &:first-child {
            padding-top: 0;
            .historiqueDate_item_deco { top: 0; }
        }
    }

    // RELATIVE -> VIEW MORE
    .main {
        position: relative;
        padding-bottom: 80px;
        max-height: 2000px;
        overflow: hidden;

        transition: $transition-base;

        &.full {
            max-height: 25000px;
            .viewMore { display: none; }
        }
    }
}
