/*** PAGE QUALITE ************************************************************************/

/*** BLOCK QUALITES *******************/
.qualities {
  margin: 40px 0;

  @include laptop {
    margin: 80px 0 0;
  }

  &_titre {
    max-width: 750px;
    margin: 0 auto 40px;
    @include fontsize(16);
    @include lineheight(20);
    text-align: center;
    font-weight: 700;
    color: $couleur_1;

    @include laptop {
      margin: 0 auto 80px;
      @include fontsize(24);
      @include lineheight(30);
    }
  }

  &_items {
    display: flex;
    flex-wrap: wrap;
  }

  &_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;

    @include laptop {
      flex-direction: row;
      width: 50%;
      margin-bottom: 100px;

      &:nth-child(2n+1) { padding-right: 50px }
      &:nth-child(2n) { padding-left: 50px }
    }

    &_illu {
      display: block;
      margin: 0 auto 15px;

      @include laptop {
        margin: 0;
        min-width: 195px;
      }
    }

    &_titre {
      margin-bottom: 20px;
      @include fontsize(16);
      @include lineheight(20);
      font-weight: 700;
      color: $couleur_2;
      text-transform: uppercase;
      text-align: center;

      @include laptop {
        text-align: left;
      }
    }

    &_text {
      text-align: center;

      @include laptop {
        text-align: left;
      }

      ul {
        text-align: left;
        margin: 8px 0 15px;

        li {
          position: relative;
          padding-left: 15px;
          color: $black;

          // PUPUCE
          &:before {
            content: "";

            position: absolute;
            top: 8px;
            left: 0;

            width: 6px;
            height: 6px;

            background: $couleur_2;
          }
        }
      }
    }
  }
}

/*** PARAGRAPHES QUALITES *******************/
.page_quality_paragraphs {
  padding: 40px 0;
  background: linear-gradient(#eff4f0, $white);
}
