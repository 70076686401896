/*** NAVIGATION *******************************************************************************/


/*** HEADER MOBILE *******************************/
.headerMob {
    @include laptop { display: none; }

    @include phonetabletonly {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        background: $white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        //border-top: 12px solid $couleur_1;
        //border-bottom: 2px solid $couleur_1;

        // RESET
        a {
            color: inherit;
            text-decoration: none;
        }

        // BT MENU
        .headerMob_btMenu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 70px;
            height: 70px;

            // PICTO
            .headerMob_btMenu_picto {
                position: relative;
                width: 30px;
                height: 22px;

                transform: rotate(0deg);
                transition: $transition-base;

                > div {
                    position: absolute;
                    left: 0;
                    right: 0;

                    height: 2px;

                    background: $couleur_1;

                    transform: rotate(0deg);
                    transition: $transition-base;

                    opacity: 1;
                    &:nth-child(1) { top: 0px; }
                    &:nth-child(2), &:nth-child(3) { top: 10px; }
                    &:nth-child(4) { top: 20px; }
                }
            }
        }

        // BT LOGO
        .headerMob_btLogo img {
            display: block;
            max-height: 80px;
            padding: 10px;
            //width: calc(100% - 140px);
            //height: 70px;

            //background: url(../img/logo/nutri-ciab.svg) no-repeat center / 130px auto;
        }

        // BT SEARCH
        .headerMob_btSearch {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 70px;
            height: 70px;

            background-image: url(../img/picto/loupe-green-darker.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size:  24px auto;
        }
    }
}


/*** MENU SLIDE ************************************/
@include phonetabletonly {

    .menu_splash { transition: $transition-base; }

    .menu_slide {
        position: fixed;
        top: 84px;
        bottom: 0;
        right: 100%;
        z-index: 20;
        overflow: auto;

        width: calc(100% - 70px);
        height: 100%;

        background: $white;
        transition: $transition-base;
    }

    // ADMIN
    .toolbar-vertical {
        .menu_slide { top: 123px; }
    }
}

/*** MENU PRINCIPAL ********************************/
.menu_principal {
    color: $couleur_1;
    @include fontsize(16);
    @include lineheight(16);
    font-weight: 500;

    // LINK
    a {
        @include supaclean;
        padding: 20px 50px 20px 10%;
        @include laptop { padding: 0; }

        // RESET
        color: inherit;
        text-decoration: none;
    }

    // LISTE
    nav > ul {
        display: flex;
        flex-direction: column;
        @include laptop {
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        li {
            @include phonetabletonly {
                width: 100%;
                border-top: 1px solid rgba($couleur_1, .4);
                &:first-child { border-top: none; }
            }
            @include laptop {
              padding: 0 19px;
              max-width: 190px;
            }

            // BT ACCUEIL
            @include laptop {
                &.child-list--accueil {
                    > a {
                        width: 27px;
                        height: 25px;

                        font-size: 0;
                        //THEME TO BE CHANGED
                        background: url(../img/picto/house-green.svg) no-repeat center / 27px auto;
                    }
                }
            }

            // BULLET
            @include laptop {
                position: relative;
                &:before {
                    content: "";

                    position: absolute;
                    top: 50%;
                    margin-top: -3px;
                    left: -3px;

                    width: 6px;
                    height: 6px;
                    background: $couleur_1;
                    border-radius: 50%;
                }
                &:first-child {
                    &:before { content: none; }
                }
                &:last-child {
                    &:after {
                      content: "";

                      position: absolute;
                      top: 50%;
                      margin-top: -3px;
                      right: -3px;

                      width: 6px;
                      height: 6px;
                      background: $couleur_1;
                      border-radius: 50%;
                    }
                }
            }

            // BT MOBILE : DEPLOY & CLOSE SS MENU
            @include phonetabletonly {
                &.has-child {

                    // BT DEPLOY
                    position: relative;
                    .btDeployMenu {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        z-index: 20;

                        width: 50px;
                      //THEME TO BE CHANGED
                        background: url(../img/picto/chevron-right-green.svg) no-repeat center / 20px;
                    }

                    // CLOSE BT
                    .menu_slide_close {
                        height: 50px;
                        background: url(../img/picto/arrow-left-white.svg) no-repeat right 20px center rgba($couleur_1, .8);
                        background-size: 30px auto;
                    }
                }
            }

            // SOUS MENU
            @include laptop {
                &:hover {
                    ul {
                        display: block;
                    }
                }
                position: relative;

                > a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    height: 29px;

                    @include fontsize(15);
                    text-align: center;
                }

                // DROP
                ul {
                    display: none;

                    position: absolute;
                    top: 29px;
                    left: 50%;
                    margin-left: -80px;
                    z-index: 20;

                    padding-top: 22px;
                    width: 170px;
                    background: white;

                    li {
                        margin: 0;
                        padding: 2px 0;
                        &:before { content: none; }

                        border-top: 1px solid rgba($couleur_1, .2);
                        &:first-child {
                            border-top: 2px solid $couleur_1;
                        }

                        &:last-child {
                          &:after { content: none; }
                        }


                        // HOVER
                        transition: $transition-base;
                        &:hover { background: rgba($couleur_1, .2); }

                        a {
                            margin: 0 10px;
                            padding: 15px 0;
                            height: auto;

                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    // CONTACT
    .menuMob_contact {
        @include laptop { display: none; }
        @include phonetabletonly {
            border-top: 1px solid rgba($couleur_1, .4);
            border-bottom: 1px solid rgba($couleur_1, .4);
        }
    }
}


// OPEN
@include phonetabletonly {

    // MENU PRINCIPAL
    .openMenu {
        .headerMob_btMenu {
            background-color: rgba($couleur_1, .2);
            .headerMob_btMenu_picto {
                div {
                    &:nth-child(1), &:nth-child(4) { opacity: 0; }
                    &:nth-child(2) { transform: rotate(45deg); }
                    &:nth-child(3) { transform: rotate(-45deg); }
                }
            }
        }
        .menu_principal.menu_slide { right: 70px; }
    }

    // MENU SEARCH
    .openSearch {
        .headerMob_btSearch { background-color: rgba($couleur_1, .2); }
        .menu_search.menu_slide { right: 70px; }
    }

    // MENU CHILD
    .menu_slide.openChild {
        right: 70px;
        z-index: 30;
    }

    // SPLASH
    .openMenu,
    .openSearch {

        // PREVENT BODY SCROLL
        overflow: hidden;

        .menu_splash {
            position: fixed;
            top: 84px;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;

            background: rgba($black, .8);
        }

        // ADMIN
        .toolbar-vertical {
            .menu_splash { top: 123px; }
        }
    }
}


/*** SCROLL TO TOP *********************************/
.scrollToTop {

	@include phonetabletonly { display: none; }
	@include laptop {
		opacity: 0;

		position: fixed;
		right: 20px;
		bottom: -100px;
		z-index: 1000;
		width: 75px;
		height: 75px;

		text-align: center;

		background-color: rgba(black, .5);
		background-image: url(https://www.motion4ever.com/wp-content/themes/motion4ever/img/svg/chevron-white.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 30px;
		border-radius: 5px;
		transition: $transition-base;

		// HOVER
		&:hover {
			background-color: rgba(black, .9);
			cursor: pointer;
		}
	}

	// SHOW
	&.scrollToTop--show {
		opacity: 1;
		bottom: 20px;
	}
}



/*** SLIDER NAVIGATION ****************************/
.front_slider_home, .front_gammes { position: relative; }

.slider_navigation {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    width: 30px;
    height: 60px;

    background-image: url(../img/bt/slider-navigation-grey.svg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 20px;

    @include laptop { background-size: 30px; }

    // RESET OUTLINE
    &:focus { outline: 0; }

    &.swiper-button-prev {
        left: 5px;
        @include laptop { left: 10px; }
        @include wide { left: 50px; }
    }
    &.swiper-button-next {
        right: 5px;
        @include laptop { right: 10px; }
        @include wide { right: 50px; }
        transform: rotate(180deg);
    }
}

.front_gammes {
    .slider_navigation {
        background-image: url(../img/bt/slider-navigation-white.svg);
    }
}


/*** SLIDER PAGINATION ****************************/
.slider-pagination {
    left: 50%;
    transform: translateX(-50%);
    bottom: 35px;
}

.swiper-pagination-bullet {
    margin: 0 5px;
    width: 10px;
    height: 10px;
    background: $white;
    border: 1px solid $couleur_1;
    opacity: 1;

    &-active {
      background: $couleur_1;
    }
}


/*** BREADCRUMB ***********************************/
.breadcrumb {
    display: none;

    @include laptop {
        display: block;
        padding: 0 20px;

        ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            @include clean;
            height: 60px;

            color: $grey;
            @include fontsize(13);
            @include lineheight(13);
            font-weight: 500;
            font-style: italic;
            list-style-type: none;

            li {
                @include clean;
                margin-left: 10px;
                &:first-child { margin-left: 0; }

                a {
                    @include clean;
                    margin-right: 10px;

                    color: inherit;
                    text-decoration: none;
                    transition: $transition-base;

                    // HOVER
                    &:hover {
                        color: $black;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}



/*** PAGER *****************************************/
.pager {

    // LIST
    ul.pager__items {
        display: flex;
        flex-direction: row;
        justify-content: center;

        margin-top: 50px;

        color: $grey;
        @include fontsize(18);
        @include lineheight(18);
        font-weight: 500;

        // ITEM
        li {
            margin-left: 15px;
            &:first-child { margin-left: 0; }

            &.pager__item--first,
            &.pager__item--previous,
            &.pager__item--next,
            &.pager__item--last {
                a {
                    @include fontsize(0);
                    background: none;
                    border: none;
                }
            }

            // FIRST
            &.pager__item--first a {
                transform: rotate(180deg);
                //background: url(../img/bt/chevron-double-blue.svg) no-repeat center / 20px auto;
                background: url(../img/bt/chevron-double-vert.svg) no-repeat center / 20px auto;
            }

            // PREVIOUS
            &.pager__item--previous a {
                transform: rotate(180deg);
                //background: url(../img/bt/chevron-blue.svg) no-repeat center / 10px auto;
                background: url(../img/bt/chevron-vert.svg) no-repeat center / 10px auto;
            }

            // NEXT
            //&.pager__item--next a { background: url(../img/bt/chevron-blue.svg) no-repeat center / 10px auto; }
            &.pager__item--next a { background: url(../img/bt/chevron-vert.svg) no-repeat center / 10px auto; }

            // LAST
            //&.pager__item--last a { background: url(../img/bt/chevron-double-blue.svg) no-repeat center / 20px auto; }
            &.pager__item--last a { background: url(../img/bt/chevron-double-vert.svg) no-repeat center / 20px auto; }

            // LINK
            a {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                @include clean;
                width: 40px;
                height: 40px;

                background: $white;
                border: 1px solid $grey_light;

                // RESET
                color: inherit;
                text-decoration: none;
            }
        }
    }
}



/*** VIEW MORE *************************************/
.viewMore {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    width: 100%;
    height: 312px;
    cursor: pointer;

    background: url(../img/bg/verti-tranp-to-white.png) repeat-x center bottom;
}
