/*** PAGE TEMOIGNAGE **************************************************************************/

/*** LISTING ************************************/
.pageTemoignageListing {
  padding: 60px 0;
  @include tablet { padding: 0 0 120px 0; }

  // TITRE
  .h1_like { margin-bottom: 40px; }

  // CONTENT
  &_content {
    display: flex;
    flex-direction: column;
    @include tablet { flex-direction: row; }
    flex-wrap: wrap;
    gap: 40px;
  }

  // ITEM
  &_item {
    @include tablet {
      width: calc( (100% - 80px) / 3 );
    }
    padding: 15px;

    background: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    transition: $transition-base;

    // RESET
    color: inherit;
    text-decoration: none;

    // HOVER
    @include laptop {
      &:hover { box-shadow: 0 3px 20px rgba(0, 0, 0, 0.45); }
    }

    // TITRE
    &_titre {
      margin-bottom: 15px;

      color: $couleur_1;
      font-family: Montserrat, sans-serif;
      @include fontsize(18);
      @include lineheight(18);
      font-weight: 700;
    }

    // CITATION
    &_citation {
      margin-bottom: 10px;
      font-style: italic;
    }
  }
}


/*** DETAILS ************************************/
.pageTemoignage {

  // CONTENT
  .pageTemoignage_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // INTRO
  .pageTemoignage_intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    padding: 30px 20px;

    background: $green;
    border-radius: 20px;

    // CITATION
    .pageTemoignage_citation {
        margin: 30px 0;
        padding: 0 60px;

        color: white;
        @include fontsize(16);
        @include lineheight(18);
        font-style: italic;
        font-weight: 700;

        // QUOTE
        position: relative;
        &:before {
            content: "";

            position: absolute;
            top: -15px;
            left: 20px;

            width: 25px;
            height: 25px;

            background: url(../img/picto/quote.white.svg) no-repeat center / cover;
        }
        &:after {
            content: "";

            position: absolute;
            bottom: -15px;
            right: 20px;

            width: 25px;
            height: 25px;

            transform: rotate(180deg);
            background: url(../img/picto/quote.white.svg) no-repeat center / cover;
        }
    }

    // AUTEUR
    .pageTemoignage_auteur {
      color: white;
      text-align: center;
    }
  }

  .pageTemoignage_paragraphe { width: 100%; }
}
