/*** CLASSICO **********************************************************************************/
html {
    font-size: 62.5%;
}


/*** FOCUS OUTLINE RESET *************************/
*:focus {
    outline: 0;
}


/*** BUTTON **************************************/
button {
    padding: 0;
    outline: none;
    background: none;
    border: 0;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}


/*** IOS FORM RESET ******************************/
// input, textarea, select { -webkit-appearance: none;  }


/*** QUICK & DIRTY *******************************/
.relative {
    position: relative;
}

.hide {
    display: none;
}

.show {
    display: block;
}


/*** DEBUG **********************************/
.bonjour {
    display: inline-block;
    animation: animsympa 3s infinite;
    animation-timing-function: linear;

    &:after {
        content: "\1F602 ¯\\(ツ)/¯ \1F602";
    }
}

@keyframes animsympa {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
