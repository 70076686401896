/*** PAGE ZONE ACTIVITE ***********************************************************************/
.zoneActivite {
    padding: 40px 0;
    background: $grey_light;

    @include laptop {
        background: url(../img/bg/hori-grey-to-white.gif) repeat-y center top;

        .main {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            max-width: 1100px;
        }
    }
}


/*** CONTENT **************************************/
.zoneActivite_content {
    @include laptop {
        padding: 50px 0;
        width: 40%;
    }

    .zoneActivite_content_titre {
        @extend .h2_like;
        @extend .souligne--fat;
        @include fontsize(22);
        @include lineheight(26);
        margin-bottom: 10px;

        @include tablet {
            @include fontsize(26);
            @include lineheight(32);
        }
    }

    .zoneActivite_desc {
        p {
            margin-top: 10px;
            &:first-child { margin-top: 0; }
        }
    }
}


/*** CONTAK ***************************************/
.zoneActivite_contak {
    margin-top: 40px;
    padding: 25px;
    background: $couleur_1;
    border-radius: 6px;

    .zoneActivite_contak_titre {
        @extend .h2_like;
        @extend .souligne--fat;
        margin-bottom: 15px;
        color: $white;
    }

    .zoneActivite_contak_add, .zoneActivite_contak_telFax {
        padding: 0 5px;
        color: $white;
        @include fontsize(14);
        font-weight: 500;
    }

    .zoneActivite_contak_telFax {
        margin-top: 15px;
    }
}


/*** CARTE ****************************************/
.zoneActivite_carte {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    @include phonetabletonly { margin-top: 50px; }
    @include laptop { width: calc(60% - 50px); }

    // IMG
    img {
        @include supaclean;
    }
}

/*** BOTTOM TEXT LIST ****************************************/
.zoneActivite_bottom_text {

    .pg_texte {
        text-align: center;
        margin: 0 auto;

        @include laptop {
            padding: 0 20px;
        }
    }

    .pg_titre_txt ul {
        max-width: 1100px;
    }

    ul {
        margin: 40px 0;
        text-align: left;
        column-count: 1;
        column-gap: 50px;

        @include tablet {
          column-count: 3;
        }

        @include laptop {
            column-count: 5;
        }
    }

    li {
        break-inside: avoid-column;
        margin-bottom: 10px;
    }
}
