/*** HOME  ************************************************************************************/
.front {
    &.bg_champetre {
        @include phoneonly {
            background: none;
            padding-bottom: 30px;
        }
    }
}

/*** SLIDER ***************************************/
.front_slider_home {
    padding: 30px 0 70px;

    // ITEM
    .slide_item {
        @include phoneonly { background: none; }
        @include laptop {
            padding: 0 60px;
        }
        @include wide {
            padding: 0;
        }

        .main {
            display: flex;
            flex-direction: column-reverse;
            @include laptop {
                flex-direction: row;
                align-items: center;
                justify-items: center;
                justify-content: space-between;
                height: 500px;
            }
        }

        // CONTENT
        .slide_item_content {
            text-align: center;
            @include laptop {
                width: 40%;
                text-align: left;
            }
        }

        // TITRE
        .slide_item_titre {
            color: $couleur_1;
            @include fontsize(20);
            @include lineheight(22);
            font-weight: 300;
            text-transform: uppercase;
            padding: 0 20px 15px;

            @include tablet {
                @include fontsize(26);
                @include lineheight(34);
                padding: 0 0 15px 0;
            }
        }

        .slide_item_desc { margin-top: 12px; }

        .btn_base { margin-top: 50px; }
    }

    .slide_item_illu {

        img {
            display: block;
            margin: 0 auto 30px;

            @include laptop {
                margin: 0 auto;
            }
        }

        @include laptop {
            width: 55%;
        }
    }

    .slide_item_illu_large {
        @include laptop {
          width: 100%;
        }
    }
}



/*** SLIDER GAMMES ***************************************/
.front_gammes {
    padding-bottom: 40px;
    background: url(../img/bg/champetre_vert.jpg) no-repeat center bottom;

    .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
    }

    &_title {
        padding-top: 10px;
        color: $white;
        text-transform: uppercase;
        font-weight: 600;
    }

    &_img {
      max-width: 182px;
    }
}



/*** ACTUS ****************************************/
.front_actus {
    margin-top: 40px;

    @include laptop {
        margin: 80px auto 0;
        padding: 0 20px;
        width: 1000px;

        .main {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            max-width: none;

            .item_actu {
                margin: 0 15px;
                width: 30%;
            }
        }
    }

    .h3_like {
        margin-bottom: 35px;
        background: url(../img/deco/underline-fat-green.svg) no-repeat center bottom / 100px 5px;
        text-transform: uppercase;
    }

    .front_actus_link {
        @include supaclean;
        margin-top: 35px;

        color: $couleur_2;
        @include fontsize(14);
        @include lineheight(14);
        font-weight: 700;
        text-decoration: underline;
        text-align: center;
    }
}



/*** VIDEO ****************************************/
.front_video {
    margin-top: 20px;
    padding: 30px 0;
    @include laptop {
        margin-top: 80px;
        padding: 0;
    }

    text-align: center;

    //@include phonetabletonly { background: $grey_light; }

    &_text p {
      text-align: left;
      margin: 30px 0 0 0;

      @include laptop {
        margin: 30px 0 0 30px;
      }
    }
}


/*** EDITO ET CHIFFRES CLES ***********************/
.front_editoETchiffre {
  //min-height: 330px;
  background: $grey_light;

  @include tablet {
    border-top: 7px solid $couleur_2;
  }

  // MAIN
  .main {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding: 0;

    @include laptop {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
    }
  }
}


/*** CHIFFRES CLES ********************************/
.front_keyFigures {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  padding: 40px 10px;
  background: url(../img/bg/granules.jpg) no-repeat center / cover;

  @include laptop {
    position: absolute;
    top: -42px;
    right: 0;
    width: 400px;
    padding: 40px;
  }

  // ITEM
  .front_keyFigures_item {
    margin-top: 30px;
    &:nth-child(1),
    &:nth-child(2) { margin-top: 0; }

    padding: 0 15px;
    width: 50%;

    color: $white;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 3px 10px rgba(0, 0, 0, 0.76);

    // BORDER
    &:nth-child(2n) { border-left: 1px solid white; }

    @include laptop {
      padding: 0 25px;
    }
  }

  // VALUE
  .front_keyFigures_value {
    margin-bottom: 5px;
    @include fontsize(33);
    @include lineheight(40);
  }

  // TITRE
  .front_keyFigures_titre {
    @include fontsize(18);
    @include lineheight(18);
  }
}



/*** EDITO ****************************************/
.front_edito {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 5%;
  text-align: center;

  @include laptop {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 40px 0;
    width: calc(95% - 400px);
    min-height: 323px;

    // CONTENEUR LAPTOP
    .front_edito_contentLaptop {
      &:nth-child(1) {
        width: 180px;
      }
      &:nth-child(2) {
        padding-left: 30px;
        width: calc(100% - 210px);
        text-align: left;
      }
    }
  }

  // TITRE
  .h2_like { margin-bottom: 15px; }

  // SS TITRE
  .h3_like {
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  // ILLUSTRATION
  .front_edito_illu {
    margin-bottom: 20px;
    @include laptop { margin-bottom: 0; }
  }

  // TEXT
  &_text {
    text-align: left;
  }

  // BT
  .btn_base { margin-top: 25px; }

  // RESET
  img { @include supaclean; }
}



/*** TEMOIGNAGES ***********************************/
.front_testimony {
  position: relative;
  margin: 0 0 10px;
  padding: 80px 0;

  @include tablet {
    margin: 0 0 50px;
  }

  &_title {
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  .main {
    position: relative;
    margin-bottom: 70px;
  }

  .temoignage_container {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    margin: 5px auto 10px;
    padding: 24px 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    @include tablet {
      max-width: 80%;
      flex-direction: row;
    }
  }

  .temoignage_img {
    position: relative;
    margin: 0 20px 10px 0;
    min-width: 75px;

    @include tablet {
      margin: 0 20px 0 0;
    }

    &:before {
      content: '';

      position: absolute;
      left: -8px;
      top: 6px;

      background-color: $couleur_3;
      -webkit-mask: url(../img/deco/quote.svg) no-repeat center center;
      mask: url(../img/deco/quote.svg) no-repeat center center;

      width: 27px;
      height: 18px;
    }
  }

  .temoignage_title {
    margin: 8px 0 8px 0;
    color: $couleur_1;
    @include fontsize(14);
    @include lineheight(20);
    font-weight: 500;

    @include tablet {
      margin: 0 0 5px 0;
    }
  }

  .temoignage_body {
    margin-bottom: 10px;
    text-align: left;

    @include tablet {
      margin-bottom: 5px;
    }
  }

  .temoignage_author {
    color: $couleur_2;
    @include fontsize(14);
    @include lineheight(20);
    font-weight: 500;
    text-align: right;
  }

  .slider-pagination {
    bottom: -35px;
  }
}

// BOUTON
.bt {
  display: block;
  width: fit-content;
  margin: 30px auto;
  padding: 10px 25px;

  color: white;
  font-family: Montserrat, sans-serif;
  @include fontsize(18);
  @include lineheight(18);

  background-color: $couleur_3;
  border: 1px solid $couleur_3;
  border-radius: 12px;
  text-decoration: none;
}


/*** CONTACT ***********************************/
.front_contact {
  display: flex;
  flex-direction: column;

  margin: 10px 0 20px;

  @include laptop {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: 10px 0;
  }

  // SPECIAL CONTENT
  .specialContent {
    @include laptop {
      width: 500px;
    }
  }

  // DESC
  &_desc {
    padding: 40px 6% 30px;
    background: $couleur_2;

    @include laptop {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      padding: 115px 60px 115px 0;
      width: 55%;

      background: $couleur_2;
    }

    @media screen and (min-width: 1500px){
      background: url(../img/bg/hexa-triple-white.png) no-repeat right 620px center $couleur_2;
    }

    // TITRE
    &_title {
      margin-bottom: 20px;

      @extend .h3_like;
      @extend .souligne--white;
      color: $white;
      text-align: left;
      text-transform: uppercase;

      p {
        @extend .h2_like;
        color: $white;
      }
    }

    // TEXTE
    &_text {
      margin-bottom: 20px;
      color: $white;
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
    }

    span {
      display: block;
      color: $white;
    }

    .mini_title {
      font-weight: 500;
      text-transform: uppercase;
    }

    .mini_text {
      font-weight: 600;
    }

    // PHONE
    &_phone {
      position: relative;
      margin: 20px 0;
      padding-left: 40px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        height: 32px;
        width: 17px;
        background: url(../img/picto/phone.svg) no-repeat center;
      }
    }

    // MAIL
    &_mail {
      padding-left: 5px;
      .mini_title {
        position: relative;
        padding-left: 35px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 16px;
          width: 21px;
          background: url(../img/picto/mail.svg) no-repeat center;
        }
      }
    }
  }

  // ILLU
  &_illu {

    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include laptop {
      display: flex;
      width: 45%;
      height: auto;
    }
  }
}
