/*** HEADER ***********************************************************************************/
.header {
    @include laptop { background: url(../img/bg/header-shadow.gif) repeat-x center bottom; }

    // MAIN
    .header_main {
        @include laptop {
            margin: 0 auto;
            padding: 0;
            max-width: 90%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        @include desktop {
            max-width: 1250px;
            padding: 0 20px;
        }
    }

    // CONTENT LAPTOP
    @include laptop {
        .header_contentLaptop {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            padding: 30px 0 25px 0;
        }
    }

    // CONTENT LAPTOP TOP (SEARCH + CONTACT)
    @include laptop {
        .header_contentLaptop_top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    // MENU PRINCIPAL
    @include laptop {
        .menu_principal { margin-top: 40px; }
    }
}

// STICKY
.headerSticky {
    .header_contentLaptop { padding: 30px 0; }
    .header_contentLaptop_top { display: none; }
    .menu_principal { margin-top: 0; }
}



/*** HEADER LOGO ***********************************/
.header_logo {
    @include phonetabletonly { display: none; }
    @include laptop {
        width: 230px;
        max-width: 230px;
    }
}

// STICKY
.headerSticky {
    .header_logo img {
        display: block;
        max-height: 80px;
        padding: 10px;
        //width: 135px;
        //height: 40px;
        //background: url(../img/logo/nutri-ciab.svg) no-repeat center / 135px auto;
    }
}

/*** SLOGAN ************************************/
.header_content_slogan {
  display: none;
  margin: 0 auto 0 0;
  padding: 0 20px;
  max-width: 500px;
  @include fontsize(22);
  @include lineheight(26);
  font-weight: 300;
  color: $couleur_2;
  text-transform: uppercase;

  @include laptop {
    display: block;
  }
}

/*** BT CONTACT ************************************/
.menu_contact {
    @include phonetabletonly { display: none; }
    @include laptop {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @include clean;
        margin-left: 80px;
        padding-left: 45px;
        height: 24px;

        color: $couleur_2;
        @include fontsize(12);
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;

        background: url(../img/picto/contact.svg) no-repeat left center / 38px 24px;
    }
}



/*** STICKY ***************************************/
@include laptop {
    .headerSticky {
        padding-top: 165px !important;

        .header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;

            background-color: white;

            // ANIMATION
            animation: stickAnim ease-in-out .3s;
            @keyframes stickAnim {
                0% { transform: translateY(-200px); }
                100% { transform: translateY(0); }
            }
        }

        // ADMIN BAR
        &.toolbar-fixed {
            padding-top: 244px !important;
            .header { top: 78px; }
        }
    }
}


//Logo
#block-m4e-basetheme-branding {
    margin: 0 auto;
    width: 300px;
}
