/*** MIXINS ***********************************************************************************/


// RESET SPACE
@mixin clean {
    margin: 0;
    padding: 0;
}

// RESET SPACE + DISPLAY BLOCK
@mixin supaclean {
    display: block;
    margin: 0;
    padding: 0;
}

// BG OPACITY
@mixin bgtrans($color, $opacity) {
    background: $color;
    background: rgba($color, $opacity);
}

// BEST SHADOW BOX
@mixin boxshadow {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
}


// CENTER UN ELEMENT AVEC UNE MAX WIDTH
@mixin maxCenter($max) {
    display: block;
    margin: 0 auto;
    max-width: $max;
}


// CENTER UN ELEMENT AVEC UNE WIDTH
@mixin wCenter($width) {
    display: block;
    margin: 0 auto;
    width: $width;
}


/*** BREAKPOINTS ***************************************/

// PHONE ONLY
@mixin phoneonly {
    @media (max-width: 599px) {
        @content;
    }
}

// TABLET ONLY
@mixin tabletonly {
    @media (min-width: 600px) and (max-width: 1023px) {
        @content;
    }
}

// PHONE AND TABLET ONLY
@mixin phonetabletonly {
    @media (max-width: 1023px) { @content; }
}

// TABLET PORTRAIT
@mixin tablet {
    @media (min-width: 600px) {
        @content;
    }
}

// TABLET LANDSCAPE
@mixin laptop {
    @media (min-width: 1024px) {
        @content;
    }
}

// DESKTOP
@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

// WIDE
@mixin wide {
    @media (min-width: 1600px) {
        @content;
    }
}




/*** FLEXBOX *******************************************/

// CENTER
@mixin flexcenter {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include laptop {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

// SPACE BETWEEN
@mixin flexsb {
    display: flex;
    flex-direction: column;

    @include laptop {
        flex-direction: row;
        justify-content: space-between;
    }
}

// FLEXBOX COLUMN
@mixin flexcolumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

// FLEXBOX START
@mixin flexstart {
    display: flex;
    flex-direction: column;

    @include laptop {
        flex-direction: row;
        justify-content: flex-start;
    }
}




/*** TYPO **********************************************/

// FONT SIZE - REM
@mixin fontsize($size) {
    font-size: $size * 1px;
    font-size: $size * 0.1rem;
}

// LINE HEIGHT - REM
@mixin lineheight($size) {
    line-height: $size * 1px;
    line-height: $size * 0.1rem;
}

// COLOR LINK
@mixin links ($link : black, $hover : black, $visited : black, $active : black, $focus : black) {
    & {
        color: $link;

        &:visited {
            color: $visited;
        }

        &:hover {
            color: $hover;
        }

        &:active {
            color: $active;
        }

        &:focus {
            color: $focus;
        }
    }
}

// COLOR LINK + UPPERCASE
@mixin upperlinks ($link : black, $hover : black, $visited : black, $active : black, $focus : black) {
    @include links ($link, $visited, $hover, $active, $focus);
    text-transform: uppercase;
}

// ALWAYS THE SAME COLOR
@mixin lazylinks ($link) {
    & {
        color: $link;

        &:visited,
        &:hover,
        &:active,
        &:focus {
            color: $link;
        }
    }
}

// ALWAYS THE SAME COLOR + UPPERCASE
@mixin upperlazy ($link) {
    text-transform: uppercase;
    @include lazylinks($link);
}

// TEXTE UPPERCASE + COULEUR + FONTSIZE
@mixin uppercolor($color, $size:14) {
    color: $color;
    @include fontsize($size);
    text-transform: uppercase;
}




/*** TRANSITION ***********************************/

// BASE
$transition-duration: .3s;
$transition-function: ease-in-out;
$transition-property: all;
$transition-base: $transition-duration $transition-function $transition-property;

// LONG
$transition-duration: .6s;
$transition-function: ease-in-out;
$transition-property: all;
$transition-long: $transition-duration $transition-function $transition-property;




/*** MIXINS EN TEST *************************************************************************************/

// RATIO
@mixin responsive-ratio($x, $y, $pseudo: false) {
    $padding: unquote(($y / $x) * 100 + '%');

    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    }

    @else {
        padding-top: $padding;
    }
}
