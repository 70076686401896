/*** FOOTER ***********************************************************************************/



/*** FOOTER RS ***********************************/
.footer_rs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 40px 0;

    background: rgba($red, .8);
    @include laptop { background: url(../img/bg/seo.jpg) no-repeat center / cover; }

    // LABEL
    .footer_rs_label {
        color: $white;
        @include fontsize(26);
        @include lineheight(26);
        font-weight: 300;
    }

    // LINK
    .footer_rs_link {
        margin-left: 20px;
        &:first-child { margin-left: 0; }

        width: 40px;
        height: 40px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: 40px;

        &.twitter { background-image: url(../img/picto/twitter-white.svg); }
        &.youtube { background: url(../img/picto/youtube-white.svg) no-repeat center / 40px; }
        &.linkedin { background: url(../img/picto/linkedin-white.svg) no-repeat center / 40px; }
        &.facebook { background: url(../img/picto/facebook-white.svg) no-repeat center / 40px; }
    }
}



/*** FOOTER SEO **********************************/
.footer_seo {
    padding: 40px 5%;
    text-align: center;
    @include laptop {
        padding: 60px 0 30px 0;
        text-align: left;
    }

    @include phonetabletonly {
        .footer_seo_illu { display: none; }
    }
    @include laptop {
        .main {
            display: flex;
            flex-direction: row;
            justify-content: center;

            // DESC
            .footer_seo_desc {
                max-width: 800px;
            }

            // ILLU
            .footer_seo_illu { width: 193px; }
        }
    }

    // TITLE
    .footer_seo_title {
        @include supaclean;
        margin-bottom: 20px;
        @include laptop { margin-bottom: 10px; }

        color: $couleur_2;
        @include fontsize(20);
        @include lineheight(22);
        font-weight: 700;
        text-align: center;

        @include laptop {
            @include fontsize(26);
            @include lineheight(30);
        }
    }

    // CONTENT
    .footer_seo_content {
        color: $black;
        @include fontsize(16);
        @include lineheight(20);
        font-weight: 400;

        @include laptop {
            @include fontsize(13);
        }

        p {
            @include supaclean;
            margin-top: 15px;
            &:first-child { margin-top: 0; }
            @include fontsize(13);
            @include lineheight(20);
        }
    }
}



/*** FOOTER MAIN *********************************/
.footer_main {
    .main {
        nav > ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;

            @include clean;
            padding-top: 25px;
            @include laptop { padding-top: 50px; }
            // @include phonetabletonly { border-top: 2px solid $grey; }

            > li {
                margin-top: 30px;
                &:nth-child(1),
                &:nth-child(2) { margin-top: 0; }
                @include laptop { margin-top: 0; }

                width: 50%;
                @include laptop { width: 15%; }

                > a {
                    @include supaclean;

                    color: $couleur_1;
                    @include fontsize(16);
                    font-weight: 600;
                    text-decoration: none;
                }

                > ul {
                    margin-top: 20px;

                    li {
                        margin-top: 10px;

                        a {
                            @include supaclean;

                            color: $grey;
                            @include fontsize(12);
                            @include lineheight(12);
                            text-decoration: none;

                            // HOVER
                            @include laptop {
                                transition: $transition-base;
                                &:hover {
                                    color: $couleur_1;
                                    text-decoration: underline;
                                }
                            }

                            // TIRET
                            position: relative;
                            padding-left: 15px;
                            &:before {
                                content: "";
                                position: absolute;
                                top: 5px;
                                left: 0;

                                width: 6px;
                                height: 2px;
                                background: $couleur_2;
                            }
                        }
                    }
                }
            }
        }

        // FAT LINK
        .footer_main_link {
            text-align: center;

            a {
                display: inline-block;
                margin-top: 30px;
                @include laptop { margin-top: 40px; }
                padding: 15px 30px;

                color: $white;
                @include fontsize(16);
                font-weight: 600;
                text-align: center;
                text-decoration: none;

                background: $couleur_2;

                // HOVER
                @include laptop {
                    transition: $transition-base;
                    &:hover {
                        color: $couleur_2;
                        background: $grey_light;
                    }
                }
            }
        }
    }
}



/*** FOOTER BOTTOM *******************************/
.footer_bottom {
    margin: 30px 0 -1px;
    @include laptop { margin-top: 40px; }
    padding: 15px 0;
    background: $couleur_1;

    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        color: $white;
        @include fontsize(14);
        @include lineheight(25);

        li {
            a {
                @include supaclean;
                margin: 0 25px;

                // RESET
                color: inherit;
                text-decoration: none;

                // CONTACT
                &.footer_bottom_contact {
                    @include laptop {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        padding-left: 45px;

                        color: $white;
                        @include fontsize(12);
                        font-weight: 700;
                        text-transform: uppercase;
                        text-decoration: none;

                        background: url(../img/picto/contact.svg) no-repeat left center / 38px 24px;
                    }
                }
            }
        }
    }
}
