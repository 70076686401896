/** BLOCK CONNECTION *************************************************************************/
.style_tile {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 900px;
    color: $black;
}

//explications
.explication {
    width: 100%;
    margin-bottom: 80px;

    .h1_like {
        margin-top: 30px;
    }

    p {
        display: block;
    }

    ul {
        margin-left: 30px;
    }
}

// Liste de couleurs
.couleurs {
    width: 32%;
    margin-bottom: 30px;

    li {
        display: flex;
        align-items: center;

        div {
            display: inline-block;
            margin: 10px;
            padding: 5px;
            border: 1px solid #ddd;
        }

        span {
            display: block;
            width: 45px;
            height: 45px;
        }
    }

    //Bg-color
    .coul_1 span {
        background-color: $couleur-1;
    }

    .coul_2 span {
        background-color: $couleur-2;
    }

    .coul_3 span {
        background-color: $couleur-3;
    }

    .coul_black span {
        background-color: $black;
    }

    .coul_white span {
        background-color: $white;
    }

    .coul_grey span {
        background-color: $grey;
    }
}

//Titre hn
.titres {
    width: 50%;
    margin-bottom: 30px;

    span {
        display: block;
        margin-bottom: 10px;
    }
}

//Boutons
.boutons {
    width: 32%;

    a {
        margin-bottom: 10px;
        text-transform: none;
    }

    span {
        display: block;
    }
}

//Formulaire
.formulaire {
    width: 32%;
}

//Menu burger
.nav_burger {
    position: relative;

    p {
        margin-left: 60px;
    }
}

//Paragraphs
.partie_paragraph {
    width: 100%;
    padding-top: 30px;

    .h1_like {
        text-align: center;
        border: none;
    }

    > div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 0 30px 0;
    }

    .description {
        text-align: center;
    }
}

.class_pg {
    display: block;
    margin: 30px auto 10px auto;
    text-align: center;
    color: cornflowerblue;

    b {
        color: black;
    }
}
